class AvaNftFamily {
  id: string;
  name: string;
  symbol: string;

  constructor(id: string, name: string, symbol: string) {
    this.id = id;
    this.name = name;
    this.symbol = symbol;
  }
}

export { AvaNftFamily };
